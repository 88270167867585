import { RootState } from '@store/index';
import { controlAction } from '@store/modules/controlReducer';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

type Props = {};

const BASE_URL_IMAGE = '/images';

export default function MapSelector({}: Props) {
  const mapStyle = useSelector((state: RootState) => state.control.mapStyle);
  const dispatch = useDispatch();

  const onClickEventHandler = (e: React.MouseEvent) => {
    e.preventDefault();

    if (mapStyle === 'satellite') dispatch(controlAction.changeMapStyle('street'));
    else if (mapStyle === 'street') dispatch(controlAction.changeMapStyle('light'));
    else if (mapStyle === 'light') dispatch(controlAction.changeMapStyle('satellite'));
  };

  return (
    <Container onClick={onClickEventHandler} title={mapStyle}>
      <Image src={`${BASE_URL_IMAGE}/${mapStyle}.png`} alt="mapStyle" width={'20px'} height={'20px'} />
    </Container>
  );
}

const Container = styled.div`
  width: 30px;
  height: 30px;

  background-color: #ebebeb;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  background-color: rgba(230, 207, 235, 0.7);
`;
