import type { NextPage, GetServerSideProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import wrapper from 'store';
import MapView from 'components/MapView';
import Controller from 'components/Controller/Controller';
// import { dehydrate, QueryClient } from 'react-query';

const Container = styled.div`
  position: relative;
`;

type Props = {};

const Index: NextPage<Props> = ({}) => {
  const { asPath } = useRouter();
  const { t: t_common } = useTranslation('common');

  return (
    <Container>
      <Controller />
      <MapView />
    </Container>
  );
};

export default Index;

export const getServerSideProps: GetServerSideProps = wrapper.getServerSideProps((store) => async (context) => {
  const activeLocale = context.locale;

  return {
    props: {
      ...(await serverSideTranslations(activeLocale as string, ['common'])),
    },
  };
});
