import React from 'react';
import { load } from '@loaders.gl/core';
import { TileLayer } from '@deck.gl/geo-layers/typed';
import { BitmapLayer } from 'deck.gl/typed';

type OverlapProps = {
  right1: number[];
  right2: number[];
  left1: number[];
  left2: number[];
};

type CustomLayerProps = {
  name: string;
  bounds: [number, number, number, number];
  url: string;
  opacity: number;
  beforeId?: string;
};

const overlap = ({ right1, right2, left1, left2 }: OverlapProps) => {
  if (left1[0] > right2[0] || left2[0] > right1[0]) return false;
  if (left1[1] < right2[1] || left2[1] < right1[1]) return false;
  return true;
};

export default function CustomTileLayer({ name, bounds, url, opacity, beforeId = 'road-label-simple' }: CustomLayerProps) {
  return new TileLayer({
    getTileData: (tile: any) => {
      const tileWithinBounds = overlap({
        right1: [bounds[2], bounds[1]],
        right2: [tile.bbox.east, tile.bbox.south],
        left1: [bounds[0], bounds[3]],
        left2: [tile.bbox.west, tile.bbox.north],
      });

      if (tileWithinBounds) {
        return load(url.replace('{z}/{x}/{y}', `${tile.index.z}/${tile.index.x}/${tile.index.y}`));
      } else {
        return null;
      }
    },
    id: name,
    pickable: true,
    tileSize: 256,
    visible: true,
    opacity: opacity,
    beforeId: beforeId,
    refinementStrategy: 'best-available',
    renderSubLayers: (props) => {
      const {
        bbox: { west, south, east, north },
      } = props.tile as any;

      return [
        new BitmapLayer(props, {
          data: undefined,
          image: props.data,
          bounds: [west, south, east, north],
        }),
      ];
    },
  });
}
