import React from 'react';
import { GeoJsonLayer } from '@deck.gl/layers/typed';

export const rgbaToArray = (color: string, alpha?: number) => {
  const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
  const result: [number, number, number, (number | undefined)?] = [0, 0, 0, 0];

  if (rgba.length === 4) {
    result[0] = parseInt(rgba[0]);
    result[1] = parseInt(rgba[1]);
    result[2] = parseInt(rgba[2]);
    if (alpha) {
      result[3] = alpha * 255;
    } else {
      result[3] = parseInt(rgba[3]);
    }
  } else if (rgba.length === 3) {
    result[0] = parseInt(rgba[0]);
    result[1] = parseInt(rgba[1]);
    result[2] = parseInt(rgba[2]);
    if (alpha) {
      result[3] = alpha * 255;
    } else {
      result[3] = 255;
    }
  }

  return result;
};

export const hexToArray = (hex: string) => {
  const res = hex.match(/[a-f0-9]{2}/gi);
  return res && res.length === 3 ? res.map((color) => parseInt(color, 16)) : null;
};

export const getGeoJsonData = ({ properties, type, coordinates }: { properties: {}; type: string; coordinates: number[][][] }) => {
  return JSON.parse(
    JSON.stringify({
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties,
          geometry: {
            type,
            coordinates,
          },
        },
      ],
    }),
  );
};

type Props = {
  data: any;
  id: string;
  color?: [number, number, number, number];
  lineColor?: [number, number, number, number];
  border: boolean;
  lineWidth?: number;
};

export default async function CustomGeoJsonLayer({ id, data, color, lineColor, border, lineWidth = 5 }: Props) {
  return new GeoJsonLayer({
    id,
    data,
    visible: true,
    pickable: true,
    getLineWidth: lineWidth,
    filled: border ? false : true,
    stroked: border ? true : false,
    getFillColor: color ? color : [93, 196, 181, 127.5],
    getLineColor: lineColor ? lineColor : [124, 45, 135, 255],
  });
}
