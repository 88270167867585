import React, { useEffect, useState } from 'react';
import { MapRef, useControl } from 'react-map-gl';
import { MapboxOverlay, MapboxOverlayProps } from '@deck.gl/mapbox/typed';
import { Layer } from '@deck.gl/core/typed';
import { juamho, ys_cover, ys_river } from './data';
import CustomTileLayer from './Layer/CustomTileLayer';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import CustomGeoJsonLayer from './Layer/CustomGeoJsonLayer';

type Props = {
  map: MapRef;
};

type TileLayerProps = {
  name: string;
  bounds: [number, number, number, number];
  url: string;
  opacity: number;
};

type GeoJsonLayerProps = {
  data: any;
  id: string;
  color?: [number, number, number, number];
  lineColor?: [number, number, number, number];
  border: boolean;
  lineWidth?: number;
};

const DeckGLOverlay = (props: MapboxOverlayProps & { interleaved?: boolean }) => {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
};

export default function MapLayer({ map }: Props) {
  const selectValue = useSelector((state: RootState) => state.control.mapSelect);
  const [mapLayer, setMapLayer] = useState<Layer[]>([]);
  const [overlayLayer, setOverlayLayer] = useState<Layer[]>([]);

  useEffect(() => {
    if (!map) return;

    console.log(selectValue);

    if (selectValue === -1) {
      setMapLayer(mapLayer.map((layer) => layer.clone({ visible: false })));
    } else if (selectValue === 0) {
      map.fitBounds([
        [juamho.bounds[0] * 0.99995, juamho.bounds[1] * 0.99995],
        [juamho.bounds[2] * 1.00005, juamho.bounds[3] * 1.00005],
      ]);

      if (mapLayer.filter((layer) => layer.id === 'juamho_imageLayer').length > 0) {
        setMapLayer(
          mapLayer.map((layer) => {
            if (layer.id === 'juamho_imageLayer' || layer.id === 'juamho_geoJsonLayer') {
              return layer.clone({ visible: true });
            } else {
              return layer.clone({ visible: false });
            }
          }),
        );
      } else {
        getImageLayer({ name: `juamho_imageLayer`, bounds: juamho.bounds, url: juamho.tiles[0], opacity: 1 })
          .then((res) => {
            getGeoJsonLayer({
              id: 'juamho_geoJsonLayer',
              data: juamho.geo_data,
              color: [102, 255, 255, 255],
              lineColor: [102, 255, 255, 255],
              border: false,
              lineWidth: 5,
            })
              .then((resp) => {
                setMapLayer(mapLayer.map((layer) => layer.clone({ visible: false })).concat([res, resp]));
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      }
    } else if (selectValue === 1) {
      map.fitBounds([
        [ys_river.bounds[0] * 0.99995, ys_river.bounds[1] * 0.99995],
        [ys_river.bounds[2] * 1.00005, ys_river.bounds[3] * 1.00005],
      ]);

      if (mapLayer.filter((layer) => layer.id === 'yeongsan_river_imageLayer').length > 0) {
        setMapLayer(
          mapLayer.map((layer) => {
            if (layer.id === 'yeongsan_river_imageLayer' || layer.id === 'yeongsan_cover_imageLayer') {
              return layer.clone({ visible: true });
            } else {
              return layer.clone({ visible: false });
            }
          }),
        );
      } else {
        getImageLayer({ name: `yeongsan_river_imageLayer`, bounds: ys_river.bounds, url: ys_river.tiles[0], opacity: 1 })
          .then((res) => {
            getImageLayer({ name: `yeongsan_cover_imageLayer`, bounds: ys_cover.bounds, url: ys_cover.tiles[0], opacity: 1 })
              .then((resp) => {
                setMapLayer(mapLayer.map((layer) => layer.clone({ visible: false })).concat([res, resp]));
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      }
    }
  }, [selectValue]);

  useEffect(() => {
    setOverlayLayer(mapLayer);
  }, [mapLayer]);

  const getImageLayer = async ({ name, bounds, url, opacity }: TileLayerProps) => {
    return CustomTileLayer({
      name,
      bounds,
      url,
      opacity,
    });
  };

  const getGeoJsonLayer = async ({ id, data, border, color, lineColor, lineWidth }: GeoJsonLayerProps) => {
    return CustomGeoJsonLayer({
      id,
      data,
      color,
      lineColor,
      border,
      lineWidth,
    });
  };

  return <DeckGLOverlay layers={overlayLayer} interleaved={true} />;
}
