import area from '@turf/area';
import bbox from '@turf/bbox';
import { lineString, polygon } from '@turf/helpers';
import length from '@turf/length';
import React, { useEffect, useState } from 'react';
import { Popup } from 'react-map-gl';
import styled from 'styled-components';
import { useAlert } from 'react-alert';

type Props = {
  coordinates: number[][];
  type: string;
  deleteFeatures: () => void;
};

const getDrawPopupLocation = (data: number[][]): [number, number] => {
  let lat;
  let maxLon;

  data.map((item) => {
    if (maxLon === null || maxLon === undefined || maxLon < item[0]) {
      lat = item[1];
      maxLon = item[0];
    }
  });

  return [maxLon, lat];
};

export default function DrawPopup({ coordinates, type, deleteFeatures }: Props) {
  const [location, setLocation] = useState<[number, number]>([0, 0]);
  const [bboxValue, setBboxValue] = useState<number[]>([0, 0, 0, 0]);
  const [unit, setUnit] = useState<number>(-1);
  const [value, setValue] = useState<number>(0);
  const alert = useAlert();

  useEffect(() => {
    setLocation(getDrawPopupLocation(coordinates));
  }, [coordinates]);

  useEffect(() => {
    if (type === 'LineString') {
      setValue(length(lineString(coordinates), { units: 'kilometers' }));
      setUnit(0);
      setBboxValue(bbox(lineString(coordinates)));
    } else {
      const areaValue = area(polygon([coordinates]));

      setValue(areaValue > 1000000 ? areaValue / 1000000 : areaValue);
      setUnit(areaValue > 1000000 ? 1 : 2);
      setBboxValue(bbox(polygon([coordinates])));
    }
  }, [coordinates, type]);

  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Delete') {
        e.preventDefault();
        deleteFeatures();
      }
    };

    if (coordinates) {
      document.addEventListener('keydown', keyDownHandler);

      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }
  }, [coordinates]);

  const onClickEventHandler = (e: React.MouseEvent, type: string) => {
    e.preventDefault();

    if (type === 'measurement') {
      navigator.clipboard.writeText(`${value.toFixed(3)} ${getUnit(true)}`);
      alert.show('넓이가 복사되었습니다!', { type: 'success' });
    } else if (type === 'features') {
      navigator.clipboard.writeText(`${JSON.stringify(coordinates)}`);
      alert.show('AOI가 복사되었습니다!', { type: 'success' });
    } else if (type === 'bbox') {
      navigator.clipboard.writeText(`${JSON.stringify(bboxValue)}`);
      alert.show('BBox가 복사되었습니다!', { type: 'success' });
    }
  };

  const getUnit = (type?: boolean) => {
    if (type) {
      if (unit === 0) {
        return 'km';
      } else if (unit === 1) {
        return 'km2';
      } else if (unit === 2) {
        return 'm2';
      } else {
        return '';
      }
    } else {
      if (unit === 0) {
        return <div>km</div>;
      } else if (unit === 1) {
        return (
          <div>
            km<sup>2</sup>
          </div>
        );
      } else if (unit === 2) {
        return (
          <div>
            m<sup>2</sup>
          </div>
        );
      } else {
        return <></>;
      }
    }
  };

  return (
    <Popup latitude={location[1]} longitude={location[0]} closeOnClick={false} closeButton={false} anchor={'top-left'}>
      <Container>
        <div className="measurement" title="Area" onClick={(e: React.MouseEvent) => onClickEventHandler(e, 'measurement')}>
          {value.toFixed(3)}&nbsp;{getUnit()}
        </div>
        <div className="features" title="Features" onClick={(e: React.MouseEvent) => onClickEventHandler(e, 'features')}>
          {coordinates}
        </div>
        <div className="bbox" title="BBox" onClick={(e: React.MouseEvent) => onClickEventHandler(e, 'bbox')}>
          {bboxValue.join(', ')}
        </div>
      </Container>
    </Popup>
  );
}

const Container = styled.div`
  padding: 5px 10px;

  background-color: #ffffff;
  border-radius: 3px;

  font-variation-settings: 'wght' 600;
  line-height: 30px;
  font-size: 15px;

  width: 200px;

  & > div {
    display: block;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    cursor: pointer;

    height: 30px;

    user-select: none;

    &.measurement {
      display: flex;
      align-items: center;
    }
  }
`;
