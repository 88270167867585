import { RootState } from '@store/index';
import { controlAction } from '@store/modules/controlReducer';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

type Props = {
  icon: any;
  title: string;
  value: 'select' | 'rectangle' | 'polygon' | 'line' | 'round' | 'delete';
};

export default function DrawToolItem({ icon, title, value }: Props) {
  const selectMode = useSelector((state: RootState) => state.control.mapDrawMode);
  const dispatch = useDispatch();

  const onClickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(controlAction.changeMapDrawMode(value));
  };

  return (
    <Container title={title} onClick={onClickHandler} className={selectMode === value ? 'selected' : ''}>
      {icon}
    </Container>
  );
}

const Container = styled.div`
  width: 30px;
  height: 30px;

  background-color: #ebebeb;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  & + & {
    margin-left: 20px;
  }

  &.selected {
    background-color: rgba(230, 207, 235, 0.7);
  }
`;
