import React from 'react';
import styled from 'styled-components';
import DrawToolBox from './DrawToolBox';
import LocationSearcher from './LocationSearcher';
import LayerSelector from './LayerSelector';
import MapSelector from './MapSelector';
import LocationMover from './LocationMover';

type Props = {};

export default function Controller({}: Props) {
  return (
    <Container>
      <MapSelector />
      {/* <LayerSelector /> */}
      <DrawToolBox />
      <LocationMover />
      <LocationSearcher />
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  height: 50px;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;

  display: flex;
  align-items: center;

  padding: 10px;
`;
