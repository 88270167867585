import { controlAction } from '@store/modules/controlReducer';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

type Props = {};

export default function LocationMover({}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const onEnterHandler = (e: React.KeyboardEvent) => {
    if (!inputRef.current) return;
    if (e.code !== 'Enter') return;

    dispatch(controlAction.changePointInfo(inputRef.current.value.split(',').map((item) => item.trim())));
    inputRef.current.value = '';
  };

  return <Input placeholder="latitude, longitude" onKeyDown={onEnterHandler} ref={inputRef} />;
}

const Input = styled.input`
  height: 30px;
  outline: none;

  border: 1px solid #ebebeb;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 30px;
  background: url(/images/search.svg) center right 10px no-repeat;
  background-color: white;

  &::placeholder {
    opacity: 0.5;
    font-variation-settings: 'wght' 300;
  }
`;
